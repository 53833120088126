



























































































































































import Component, { mixins } from 'vue-class-component';
import WidgetMixins from '../../../../../mixins/WidgetMixins';
import DialogMixins from '../../../../../mixins/DialogMixins';
import { currencyFormat, loadView, loadWidget } from '@/utils/helpers';
import { namespace } from 'vuex-class';
import { ICalculatedValues, ICustomers, IPaginate, ISenderID, IStore, IUser } from '@/types/types';
import timestamp from '@/filters/timestamp';
import mediumZoom from 'medium-zoom';
import { Watch } from 'vue-property-decorator';
import { debounce } from 'lodash';

const customersModule = namespace('customers');
const authModule = namespace('auth');
@Component({
  name: 'AllSenderIDs',
  components: {
    InternetConnection: loadWidget('pages/InternetConnection'),
    ToolBar: loadWidget('widgets/ToolBar'),
    SnackBar: loadWidget('widgets/SnackBar'),
    TopUpAccountDialog: loadView('pages/products/dialogs/TopUpAccountDialog'),
  },
  filters: {
    currencyFormat,
    timestamp,
  },
})
export default class AllSenderIDs extends mixins(WidgetMixins, DialogMixins) {
  @customersModule.Getter('getAllSenderIDs') senderIds!: ISenderID[];
  @customersModule.Getter('getPagination') pagination!: IPaginate;
  @authModule.Getter('getUserDetails') details!: IUser & IStore;
  menu: boolean = false;
  options: any = {};
  searchTerm: string | null = null;
  date: string | null = null;
  status: string = 'ALL';
  statuses: Array<string> = ['ALL', 'PENDING', 'APPROVED'];
  headers: Array<{ text: string; value: string }> = [
    {
      text: '',
      value: 'avatar',
    },
    {
      text: 'Date',
      value: 'createdAt',
    },
    {
      text: 'Name',
      value: 'name',
    },
    {
      text: 'User',
      value: 'user',
    },
    {
      text: 'Status',
      value: 'isApproved',
    },
    {
      text: 'Actions',
      value: 'actions',
    },
  ];

  @Watch('status')
  onStatusChange(value: string) {
    const query = `?page=${this.currentPage}&size=${this.pagination.itemsPerPage}&filter=${this.status}${
      this.searchTerm ? '&search=' + this.searchTerm : ''
    }`;
    this.$store.dispatch('customers/listAllSenderIDs', query);
  }

  searchTermChange(term: string): void {
    if (term) {
      const query = `?page=${this.currentPage}&size=${this.pagination.itemsPerPage}&search=${term}${
        this.status ? '&filter=' + this.status : ''
      }`;
      this.$store.dispatch('customers/listAllSenderIDs', query);
    } else {
      this.$store.dispatch(
        'customers/listAllSenderIDs',
        `?page=${this.currentPage}&size=${this.pagination.itemsPerPage}`,
      );
    }
  }

  handleOnSearchTermChange = debounce(this.searchTermChange, 800);
  @Watch('searchTerm')
  onSearchTermChange(term: string): void {
    this.handleOnSearchTermChange(term);
  }

  @Watch('options', {
    deep: true,
  })
  onOptionsChange(paginate: IPaginate): void {
    const query = `?page=${paginate.page}&size=${paginate.itemsPerPage}${
      this.searchTerm ? '&search=' + this.searchTerm : ''
    }`;
    this.$store.dispatch('customers/listAllSenderIDs', query);
  }

  topUpAccount(payload: ICalculatedValues): void {
    this.$store.dispatch('customers/topUpCustomerAccount', {
      body: payload,
      id: this.$store.getters['customers/getCustomerDetails']?.id,
    });
  }

  created(): void {
    this.$store.dispatch('auth/me');
  }
  mounted(): void {
    mediumZoom('.productPreview');
  }
}
